<template>
  <div>
     
        <div class="row">
            <div class="col-md-12">
                <h3 v-if="pgLoading" class="bottom40 margin40 webkit-animation webkit-50per-50"></h3>
                <h3 v-if="!pgLoading" 
                    class="bottom40 margin40"
                    :class="($i18n.locale == 'ar') ? 'hacen_algeria rtl' : ''">
                    {{ $t('app.best_deal_properties') }}
                </h3>
            </div>
        </div>

        <div id="agent-2-slider" class="col-md-12">

            <div v-if="!pgLoading" class="owl-controls clickable">
                <div class="owl-buttons">
                    <div @click="prev()" 
                        id="swiperBtnPrev" 
                        class="owl-prev">
                        <span class="fa fa-angle-left"></span>
                    </div>
                    <div @click="next()" 
                        id="swiperBtnNext" 
                        class="owl-next">
                        <span class="fa fa-angle-right"></span>
                    </div>
                </div>
            </div>



            <Swiper v-if="pgLoading" ref="mySwiper" :options="swiperOptions">
              <SwiperSlide v-for="(item, index) in 3" :key="index">

                  <BestDealLoading></BestDealLoading>

              </SwiperSlide>
            </Swiper>

            <Swiper v-if="!pgLoading" ref="mySwiper" :options="swiperOptions">
              <SwiperSlide v-for="(item, index) in items" :key="index">

                  <BestDealCard :item="item"></BestDealCard>

              </SwiperSlide>
            </Swiper>

          </div>

  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
    name: 'BestDealAside',
    props: [
        'title',
        'refs',
        'paginate'
    ],
    components: {
        Swiper,
        SwiperSlide,
        BestDealLoading: () => import('@/components/BestDealLoading.vue'),
        BestDealCard: () => import('@/components/BestDealCard.vue'),
    },
    directives: {
        swiper: directive
    },
    data(){
        return {
            //
            pgLoading: true,
            items: [],

            swiperOptions: {
                name: 'mySwiper',
                loop: true,
                speed: 700,
                autoplay: true,
                spaceBetween: 30,
                slidesPerView: 1,
                navigation: {
                    nextEl: '#swiperBtnNext',
                    prevEl: '#swiperBtnPrev'
                },
                breakpoints: {
                    1024: {
                    slidesPerView: 1,
                    spaceBetween: 20
                    },
                    768: {
                    slidesPerView: 1,
                    spaceBetween: 30
                    },
                    640: {
                    slidesPerView: 1,
                    spaceBetween: 20
                    },
                    320: {
                    slidesPerView: 1,
                    spaceBetween: 10
                    }
                },
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            },
        }
    },
    computed: {
        swiper() {
            return this.$refs.mySwiper.$swiper
        }
    },
    mounted() {},
    created() {
        //
        this.fetchData();
    },
    methods: {
        //
        fetchData() {
            this.pgLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
            };
            const options = {
                url: window.baseURL+'/properties',
                method: 'GET',
                data: {},
                params: {
                    bestDeal: true,
                    paginate: 3
                }
            }
            this.axios(options)
            .then(res => {
                this.pgLoading = false;
                this.items = res.data.items;
            })
            .catch(() => {})
            .finally(() => {});
        },

        prev() {
            this.$refs.mySwiper.$swiper.slidePrev();
        },

        next() {
            this.$refs.mySwiper.$swiper.slideNext();
        },

    }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
